body{
	color:white;
}
.start-text{
	margin:auto;
	margin-top: 50px;
	margin-left:35vw;
	color: black;
}
.email-container{
	margin:auto;
	max-width: 50vw;
	background-color:grey;
	padding: 20px
}
textarea{
	height:200px;
	width: 200px;
}
.phoneInput{
	max-width: 200px
}
.submitButton{
	background-color: black;
	color:white;
}