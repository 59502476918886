
.productDetails{
	color:white;
}

.image{
	/* margin-top: 10px; */
	/* height:40vw; */
	margin-top: 50px;
	width: 70vw;
	max-width: 600px;
}
.imageFirst{
	width: 37vw;
	max-width: 400px;
}
.square{
	z-index: 0 !important;
}
.productContainer{
	margin:auto;
	align-items: center;
	text-align: center;
	display:flex;
	flex-direction: column;
	position: relative;
	margin-top: 50px;
	/* padding: 0px 100px 150px 100px; */
	padding:30px 30px 150px 20px;
	margin-left: 50px;
	margin-right: 50px;
	background-color: white;
	z-index: 1000 !important;
}
.details{
	margin-top: 50px;
	font-family: "Actor", sans-serif;
	max-width: 80vw;
	text-align: left;
	text-indent: 10px;
	line-height: 1.5;

}
.productName{
	font-size: 35px !important;
	text-align: center;
	max-width: 50vw
	
}
.ingredientContainer{
	width: 78vw;
	align-self: left;
	margin:none;
	text-align: left;
	list-style:square;
	line-height: 1.4;
	/* margin-left: -40px; */
	margin-top: 50px;

}

button{
	/* background-color: white; */
	/* border-radius: 150px; */
	padding:15px;
	margin-top: 30px;
	color: var(--secondary);
}
.addToCartButton{
	background-color: white;
	color: black;
	border-color: var(--light);
}
@media (max-width: 800px){
	.productName{
		max-width:80vw
	}
	.productContainer{
		margin-left: 20px;
	margin-right: 20px;
	}
	.productName{
		font-size: 25px !important;
	}
}
@media screen and (max-width:620px){
	.productContainer{
	margin-left: 10px;
	margin-right: 10px;
	}
	.productName{
		font-size: 25px !important;
	}
}
/* @media  (max-width: 710px){
	.details{
		margin-top: 30px;
	
	}
} */