.bigContainer{
	/* margin-top: 100px; */
	/* margin-left:10px; */
	list-style: none;
}
.cartContainer{
	background-color: white;
	font-family: Georgia, 'Times New Roman', Times, serif;
	color:black ;
	min-height: 50vw;
	height: max-content;
	position: relative;
	margin-left: 20px;
	margin-right: 20px;
	padding-bottom: 100px;
	border-radius: 20px;
}
.displayContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 25px 100px 20px 30px;
	
	
}
.line{
	height: 3px;
	background-color: black;
	width: 100vw;
}

.totals{
	/* margin-top: 20vw; */
	/* float: bottom; */
	/* position:absolute; */
	margin-top: 100px;
	font-family:Georgia, 'Times New Roman', Times, serif;
	bottom:90px;
}
.remove{
	width:40px;
	height: 50px;
	overflow-y: hidden;
	font-weight: bolder;

	font-size:20px;
	
	margin-top: -15px;
	margin-right: 20px;
	margin-left: 20px;
	background-color: white;
	border-color: white;
		border-style: none;
}
.cartbuttons{
	/* position:absolute; */
	/* bottom:40px; */
	background-color: var(--light);
	color: var(--main-color);
	
}
.cartList{
	margin-top: 50px;
	margin-left: 30px;
}
.clearButton{
	margin-left: 50px;
}
.cartLine{
	font-family:Georgia, 'Times New Roman', Times, serif;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 20px 120px 20px 0px;
	color:black !important;
}
.img{
	height:100px;
}
.nameCart{
	width:200px;
}
.qtyText{
	margin-left: 140px;
}
.quantityRow {
	display: flex;
	flex-direction: row;
	
}
.quantityRow, .totalIndividual {
	margin-top: 50px;
}
.adjust{
	margin-left:-120px !important;
}
 @media (max-width:670px){
 .cartLine{
	 display: flex;
	 flex-direction: column;
	 align-items: center;
 }
 .nameCart{
	 text-align: center;
 }
 .img{
	 align-self: center;
	 /* margin-left: 50px; */
	 width:200px;
	 height: auto;
 }
 .qtyText, .totalText {
	 visibility: hidden;
 }
 .itemText{
	 /* margin: left; */
	 /* text-align: center; */
	 z-index: 100;

 }
 
	.displayContainer{
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: auto;
		margin-top: 25px;
		margin-bottom: 20px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.remove{
		margin-top: -10px;
		font-size: 20px;
		text-align: center;
		height: 40px;
		width:40px;
		border-color: white;
		border-style: none;
	}
 
 }