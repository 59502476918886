@import "@fontsource/petit-formal-script";
:root {
    --main-color:#2d694a;
    --secondary: #262926;
    --third:#0081FF;
    --forth: #ffffff;
	--gold:#0081FF;
	--detail:#FC7A55;
	--light: #93c794;

}
.but{
	text-decoration: none;
}
body{ 
        background-color: white;
        display: flex-start;
        justify-content: start;
        align-items: center;
        font-family: 'Cormorant Garamond', serif;
}
.image-container{
	margin-top: 0em;
	margin-left:0em;
	height:50%;
	justify-content: left;
	align-items: left;
	
}
.square{
	height: 110vh;
	width:100%;
	z-index:0 !important;
	position: fixed;    
	overflow-x: hidden;
	margin-top: -150px;
	
}
.container-whole{
	z-index:1000 !important;
	position: relative;
	/* background-color: white; */
	margin-top: 200px;
	padding-top: 50px;
}
.productImage{
	height: 40vw;
	width:100%;
	filter:none;
	height: 60vw;
}
.big-font-top{
	position: relative;
	z-index: 8 !important;
	font-size: 8vw;
	text-align: left;
	margin-left: 50px;
    color: white;
	/* font-family: 'Petit Formal Script', cursive; */
	font-family:Georgia, 'Times New Roman', Times, serif;

	margin-top: 200px;
	
}
.smalla{
	position: relative;
	font-size:20px;
    font-family:Georgia, 'Times New Roman', Times, serif;
	text-align: left;
	color: white;
	margin-left:70px;
	margin-right:10px;
	max-width: 90vw;
	line-height: 1.5;
	margin-top: 70px;;
	z-index: 1000 !important;

}
.para{
	font-size: 1.2em;
    text-align: left;
	margin-top: 6em;
	max-width: 40%;
	margin-left: 10%;
	color: var(--secondary);
	display: block;
	font-family: 'Petit Formal Script', cursive;
	
	
}


.second-home{
	margin-top:8em;
	
}
.about-container{
	margin-top:50px;
	/* margin-left: 20px; */
	background-color: white;
	padding-top: 100px;
	padding-bottom: 200px;
	z-index: 1000 !important;
}
/*text images*/
.pickingImage{
	width:45vw;
	height: auto;
	max-height: 350px;
	max-width: 455px;
	float:right;
	padding:20px;
	margin-top: -240px;
}
.aboutTitle{
	margin-top: 100px;
	font-size: 30px;
}
.aboutDetails{
	max-width: 50vw;
	margin-top: 70px;
	font-family:Georgia, 'Times New Roman', Times, serif
	
}
.aboutTitle, .aboutDetails{
	margin-left:20px;
}

.individual-container{
	/* background-color: var(--detail); */
	background-color: white;
	align-items: center;
	width:fit-content;
	padding: 40px;
	width: 40vw;
	margin:auto;
	border-radius: 2px ;
	margin-top: 50px;
	color: black;
}
.individual-container:hover{
	transform: scale(1.2, 1.2);
	transition: .5s;
	
}
.productContainer{
	align-items: center;
	list-style: none;
	color:black;
	justify-content: center;
	width: 100;
	margin:auto;
	padding-bottom: 150px;
	/* background-color: var(--light); */
	/* background-image: url(''); */
	/* margin-top: 200px !important; */
}
.names{
	max-width: 400px;
	color:black;
	/* text-align: center; */
	text-align: left;
	margin-top:0px;

	
}
.listimage{
	width: 20vw;
	height: auto;
	/* margin: auto; */
}

.price{
	margin-top: 18px ;
}
.addTo{
	border-radius: 0px;
	background-color: white;
	border-color: var(--light);
	cursor: pointer;
}
.sale{
	font-size: 45px;
	color: white;
	/* font-weight: bolder; */
	margin:auto;
	text-align: center;
	margin-top: 50px;
}
.productLandingName{
	font-size: 15px !important;
	margin-top: 20px;

}
.productLandingName, .price, .addTo {
	margin-left: 40px;
}

@media (min-width:778px){
	.itemsContainer{
		/* align-items: center; */
		
		/* justify-content: center; */
		/* width: 100; */
		/* margin:auto; */
		display: flex;
		flex-direction: row !important;
		flex-wrap: wrap;
		align-items: left;
	}
	.individual-container{
		max-width: 300px;
	}
	.sale{
		text-align: left !important;
		margin:none !important;
		margin-left:80px;
	}
	
}

@media (min-width:1500px){
	/* .big-font-top{
		font-size:3vw;
		margin-top:-7.3em;
	} */
}

@media (max-width:778px){
	.square{
		/* margin-top: -400px; */
		/* height:800px; */
		height: 110vh;
		
	}
	.big-font-top{
		font-size: 13vw;

	}
	.pickingImage{
		width:90vw;
		height: auto;
		margin-top: 40px;
		float:none;

	}
	.aboutDetails{
		max-width: 85vw;
		margin-top: 70px;
		font-family:Georgia, 'Times New Roman', Times, serif
		
	}
	.sale{
	
		margin-top: 80px;
	}
	.individual-container{
		width:80vw;
	}
	.individual-container:hover{
		transform: none !important;
	}


}

@media (max-width:500px){
	.listimage{
		width:40vw
	}

	.square{
		height: 110vh;
		/* height:850px; */

	}
	.big-font-top{
		font-size: 13vw;


	}

	.smalla{
		font-size: 1.2rem;
		margin-top: -50;
		/* margin-left:0; */
		display: block;
	
	}
	.para{
		font-size: 5vw;
		margin-top: 7vw;
		text-align: center;
		max-width: 80%;
	}


button{
  border-radius: 15px;
  margin-left: 5%;
  margin-top: 4em;
}


}
