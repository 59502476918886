.page{
	display:flex;
	flex-direction: column;
	margin:auto;
	text-align: center;
}
input{
	margin-top: 10px;
	text-align: center;
}

.gmApi{
	/* display:block;
	min-height:max-content;
	max-height: none;
	height:300px !important; */
	overflow: visible !important;

}