/* @Copyright 2021, KCB Software & Design, In cooperation with Grindstone Capital */
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Lato:wght@700&display=swap'); */
:root {
    --main-color:#2d694a;
    --secondary: #000000;
    --third:#0081FF;
    --forth: #ffffff;
	--gold:#0081FF;
	--detail:#FC7A55;

}
.body{
	overflow-x: hidden;
	
}
.navbarcontainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.title {
	
	margin-left:30px;
	text-decoration: none;
	color: black;
	font-weight: bolder;
	z-index: 1000000 !important;
	position: relative;
	width: 10vw;
	height: auto;
	background-color: white;
	padding:10px;
	border-radius: 100px;
} 


.navbar {
	background-color: var(--detail);
	height: 150px;
	display: flex;
	align-items: center;
	overflow-x: hidden;
	overflow-y: hidden;
	z-index: 10000;
}

.menu-bars{
        font-size: 2rem;
        background: none;
		margin-left: 80vw;
		color: black;
        
}

.nav-menu{
    background-color: var(--detail);
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content:center;
    position: fixed;
    top: 0;
    right: -80%;
    transition: 950ms;
    list-style-type: none;
	z-index:100000;
}

.nav-menu.active {
    right: 0;
    transition: 650ms;
}
.nav-text{
    display: flex;
    justify-content: start;
    align-items: space-between;
    padding: 8px 0px 8px 10px;
    list-style: none;
    height: 20px;
    font-size: 1em;
	font-family: 'Lato', sans-serif;
	
    
}


/* .nav-text a{
    text-decoration: none;
    color: var(--secondary);
    width: 100%;
    display: flex;
    align-items:center;
    padding: 0 16px;
    border-radius: 4px;
    padding: 20px;
    font-weight: bold;
    margin-top: 6.3em;
    
} */
.nav-text a{
	text-decoration: none;
	color: white;
	font-size: 18px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items:center;
	padding: 0 16px;
	border-radius: 4px;
	padding: 20px;
	font-weight: bold;
	margin-top: 4em;
	padding-bottom: 10em;
	height:20%;
	margin-top: 3.5em;
}

.nav-menu-items {
    width: 100%;
	
    list-style-type: none;
    
}
.navbar-toggle{
    /* background-color: var(--detail); */
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
	
}

span {
    margin-left: 1vw;
}
.logo{
    /* margin-top: 0em;
    margin-left: 0vw; */
    width: 10em;
    height: auto;
	/* float:left; */
}


.nav-text, .nav-text-desk a{
	margin-top: 0em;
	height:9%;
	width:110;
}
.carty{
	float: right;
	font-size: 25px;
	margin-right: 3vw;
	color:white;
	margin-top: 20px;
	/* top:200px; */
	text-decoration: none;
	/* position: relative; */
	z-index:100000;
	position: sticky;
}
.number{
	color:white;
}


@media(min-width:1900px){
   
	.nav-text a{
        margin-top: 4.5em;
        font-size: .7vw;
        }

}



@media (max-width:1000px){
    
    .menu-bars{
        visibility: visible;

        font-size: 2rem;
        background: none;
     
    }
    

    .nav-text a{
        text-decoration: none;
        color:  white;
        font-size: 18px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items:center;
        padding: 0 16px;
        border-radius: 4px;
        padding: 10px;
        font-weight: bold;
		margin-top: 2em;
    }
    .nav-text  a:hover {
		background-color: var(--detail);
		
    }
	.nav-text, .nav-text-desk a{
		margin-top: 0em;
		height:9%;
		width:110;
	}
    span {
        margin-right: 16px;
    }
    .logo{
        position: absolute;
        /* margin-top: .2em;
        margin-left: 15em; */
        left:1.5%;

    }
	.navbar{
		width:100%;
	}
	.logo{
		margin-top: -4.5em;
		width: 9em;
    height: auto;
	}

	
    
}
@media  (max-width:500px){
	.nav-menu, .nav-text-home{
	 right:-95%;
	 visibility: hidden;
	} 
	.title{
		width: 25vw;
	}
	
	.nav-menu.active {
	 /* right: 10%; */
	 visibility: visible;
	}
	
	.nav-menu.active {
		right: 0;
		transition: 400ms;
	}
	
}