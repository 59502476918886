@import "@fontsource/actor";
@import "@fontsource/petit-formal-script";

*{
	box-sizing: border-box;
    margin: 0;
    padding: 0;
	/* font-family: "Petit Formal Script", cursive; */
	font-family:Georgia, 'Times New Roman', Times, serif;
	overflow-x: hidden;
}