/* @Copyright 2021, KCB Software & Design, In cooperation with Grindstone Capital */
:root {
    --main-color:#9fd6ba;
    --secondary: #000000;
    --third:#0081FF;
    --forth: #ffffff;
	--gold:#0081FF;
	--detail:#45a86d;
	--light: #93c794;

}

.footer{

	background-color:var(--light);
	color: white !important;
	text-align: center;
	margin-top: 3vw;
	overflow-x: hidden;
	/* left: 0; */
	width: 100vw;
	display: flex;
	font-size:.9em;
	overflow-x: hidden;
	position: relative;
	z-index:1000;
  }
  
  .logo-footer{
	  width: 8em;
	  height:8em;
	  margin-left: -2em;
	  margin-top: 3em;
	}
	
	.containers{
		margin-top:9vw;
		text-align:left;
		line-height: 2;
		width: 40vw;
	}	
	.generic_links{
		margin-left:6vw;
		color:white;
	}
	.info_links{
		
		margin-top:-10vw;
	}
	.logoFooter{
		width:150px !important;
		margin-top: 0px !important;
	}
	
	
	.link-container{
		width:100vw;
		display: flex;
		flex-direction: row;
	}
	.term_links{

		margin-top: 120px;
	}
	.term_links :hover{
		color:black !important;
	}
	.terms,.privacy{
		
		float:right;
		word-wrap: none;
		font-family: Georgia, 'Times New Roman', Times, serif;
	}
	.terms,.privacy, .links{
		text-decoration: none;
		font-weight: bold;
		color:white;
		
	}

	.copyright{
		color: white;
		margin:auto;
		margin-top:50px;
		align-items: center;

		width:100vw;
		position: relative;
		padding-bottom: 20px;

		
	}
	.disclaimer{
		color: white;

		font-family: Georgia, 'Times New Roman', Times, serif;
		max-width:600px;
		margin:auto;

	}
	@media (max-width:600px){
		.logoFooter{
			width:120px !important;
			margin-left: 0px !important;
			margin-top: 50px !important;
		}
		.copyright{
			margin:auto;
			margin-top: 30px;
			align-items: center;
			padding-bottom: 20px;;
		}
		.disclaimer{
		width:80vw;
		text-align: center;
		margin: auto;
		padding-bottom: 20px;;
	}
  }
